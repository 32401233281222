.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 2px 4px 0 rgba(21, 27, 38, 0.15);
  margin: 0 50px;

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }

    svg {
      font-size: 4em;
      color: #00b9b7;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__time {
      color: #000;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      margin: 0 20px;
      height: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border: 1.5px solid white;
        border-radius: 50%;
        background-color: #f08a01;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-height: 800px) {
    .player {
      margin: 0 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

.player-exam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 2px 4px 0 rgba(21, 27, 38, 0.15);
  // margin: 0 20px;
  background-image: linear-gradient(to left, #00e1a0, #00b9b7);
  padding: 0px 24px;

  .controls {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }

    svg {
      font-size: 4em;
      color: #fff;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__time {
      color: #000;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      margin: 0 20px;
      height: 2px;
      display: flex;
      align-items: center;
      background: #fff;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border: 1.5px solid white;
        border-radius: 50%;
        background-color: #f08a01;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-height: 800px) {
    .player {
      margin: 0 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}
