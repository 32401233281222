.item-checkbox {
  display: flex;
  align-items: center;
  margin: 3px;
  cursor: pointer;

  label {
    font-size: 18px;
    margin-left: 8px;
    margin-top: -3px;
  }
}

.item-grammar1 {
  display: flex;
  margin: 12px 3px;

  .order {
    font-size: 18px;
  }

  .begin-text {
    font-size: 18px;
  }

  .end-text {
    font-size: 18px;
  }

  input {
    margin: 2px 6px;
    padding: 6px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #707070;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 13px;
    }
  }
}

.item-pronunuciation {
  u {
    text-decoration: none;
    border-bottom: 1.5px solid #454545;
  }
}

.item-grammar9 {
  margin-top: 16px;
  margin-bottom: 8px;

  u {
    text-decoration: none;
    border-bottom: 1.5px solid #454545;
  }
}

.label {
  font-size: 18px;
}
.title-question {
  display: block;
  font-size: 18px;
  font-family: "Myriadpro-SemiBold";
}

.title_question_width {
  width: 82%;
}

.underline {
  text-decoration: underline;
}

.item-reading1,
.item-writing,
.exam-listening {
  margin: 8px 3px;

  .key-word-sugguest {
    display: flex;
    width: 75%;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
    border: 1.5px solid #70707070;
    border-radius: 16px;
    margin: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    span {
      width: 25%;
      margin: 8px 0px;
      font-size: 18px;
      // font-family: 'Myriadpro-SemiBold';
    }
  }

  .content-document {
    .title-content {
      font-size: 18px;
      text-align: center;
    }
    span {
      font-size: 18px;
      white-space: break-spaces;
      display: inline-block !important;
      padding-bottom: 0.6rem !important;
    }
  }

  .content-audio {
    margin: 30px 0px;
    // width: 50%;
    width: 540px;
  }

  .content-question {
    margin-top: 16px;

    .item-question {
      margin: 8px;
    }

    .order {
      font-size: 18px;
      font-family: "Myriadpro-SemiBold";
    }

    .begin-text {
      font-size: 18px;
    }

    .end-text {
      font-size: 18px;
    }

    input {
      margin: 0px 6px;
      padding: 8px;
      font-size: 16px;
      border-radius: 6px;
      border: 1px solid #707070;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 13px;
      }
    }
    .full-width {
      width: 100%;
      margin: 6px;
    }

    .width-50-percent {
      width: 50%;
      margin: 6px;
    }

    .d-flex {
      display: flex;
      align-items: center;
      margin: 6px;
    }
  }
}

.text-explain-exam {
  margin-top: 16px;
  margin-left: 16px;

  label {
    font-weight: bold;
    font-size: 18px;
  }

  div {
    font-size: 18px;
  }
}

.item-exam-result-text {
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  .text-answer-false {
    color: #be1e2d;
    font-size: 18px;
  }

  .text-answer-true {
    color: #009444;
    font-size: 18px;
  }
}

//Check box square css
.img_checkBox {
  width: 1.7rem;
  height: auto;
}
