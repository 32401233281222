.bar_range {
  padding: 1.5rem 0rem 0rem;

  .bar__progress {
    // flex: 1;
    // margin: 0 20px;
    height: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 1.5rem;
    position: relative;

    .bar__progress__knob {
      position: absolute;
      height: 1.6875rem;
      width: 1.6875rem;
      border: 1.5px solid #ffdb5a;
      border-radius: 50%;
      background-color: var(--white-color);
    }
  }
}
