.register_update_info {
    .step_register_name {
        margin-bottom: 20px;
    }

    .field_detail_group_create_account {
        font-size: 1.7rem;
        margin: 10px 0;
        color: #00beb4;
        font-weight: bold;
    }
    .title_register {
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 22px;
    }
    &_content {
        margin: 0 auto;
        margin-top: 118px;
        @media screen and (max-height: 800px) {
            margin-top: -130px;
        }
        max-width: 950px;
        .form_container {
            display: flex;
            .left {
                width: 450px;
                margin-right: 50px;
            }
            .right {
                width: 450px;
            }
        }

        &_gender {
            width: 65px;
            height: 65px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #4a4848;
            border-radius: 10px;
            cursor: pointer;
            .male_active,
            .female_active {
                display: none;
            }
            .male,
            .female {
                display: block;
            }
            .content {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            &.gender_male {
                img {
                    width: 19px;
                    height: auto;
                }
            }
            &.gender_female {
                img {
                    width: 14px;
                    height: auto;
                }
            }
            span {
                font-size: 18px;
            }
            &.active {
                .male_active,
                .female_active {
                    display: block;
                }
                .male,
                .female {
                    display: none;
                }
                &.gender_male {
                    span {
                        color: #00b9b7;
                    }
                }
                &.gender_female {
                    span {
                        color: #f75caa;
                    }
                }
            }
        }
        .field_province {
            .drop_down {
                @media screen and (max-height: 800px) {
                    max-height: 260px;
                }
            }
        }
        .field_district {
            .drop_down {
                @media screen and (max-height: 800px) {
                    max-height: 200px;
                }
            }
        }
        .field_school {
            .drop_down {
                max-height: 150px;
            }
        }
    }
    .button_submit {
        text-align: center;
        margin-bottom: 22px;
        margin-top: 36px;
        button {
            width: 210px;
            height: 50px;
            line-height: unset;
            font-size: 18px;
            border-radius: 25px;
        }
    }
    .login_text {
        cursor: pointer;
        &:hover {
            p,
            a {
                color: #35b5c3;
            }
        }
    }
    .field_item {
        margin-bottom: 34px;
        .field_birthday {
            margin-bottom: 0px;
        }
    }
}
