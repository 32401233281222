.c-dropdown-custom {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px 6px;

    .item-selected {
        z-index: 10;
        cursor: pointer;
    }

    .item-default {
        border: 1px solid #ccc;
        padding: 8px 16px;
        border-radius: 20px;
        min-width: 120px;
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(to right, #00b9b7, #00e1a0);

        img {
            object-fit: contain;
        }

        img.reverse {
            transform: rotate(180deg);
        }

        span {
            margin-left: 6px;
            border-left: 3px solid #fff;
            padding-left: 6px;
            color: #fff;
            flex: 1;
        }
    }

    .list-options {
        position: absolute;
        top: 15px;
        max-height: 500px;
        background-color: #fff;
        padding: 30px 16px 16px;
        right: 0;
        left: 0;
        border-radius: 0px 0px 20px 20px;
        box-shadow: 0px 0px 2px inset #707070;
        z-index: 9;
        overflow: auto;

        .option {
            padding: 8px 0px;
            cursor: pointer;
            border-bottom: 1px solid #ccc;
        }

        .option:last-child {
            border-bottom: none;
        }
    }
}