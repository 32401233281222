@import "/src/_styles/mixin";

.off_background_base {
    position: absolute;
    top: 36px;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.first_step-container {
    background-color: #EDFFF6;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    padding-top: 5rem;
    padding-bottom: 4rem;

    .first_step-header {
        text-align: center;
        margin: 0 auto;
        // margin-top: -15%;

        .logo-sunday {
            width: 14.375rem;
            object-fit: contain;
        }
    }

    .content-header-completed {
        width: 100%;
        margin-top: 5.87rem;

        .content-header-font-completed {
            width: 55.8125rem;
            text-align: center;
            font-size: 3rem;
            font-family: "MyriadPro-Bold";
        }

        .btn_custom {
            margin-top: 5%;
            background-image: linear-gradient(to right, #00b9b7, #00e1a0);
            border-radius: 1.25rem;
            color: #FFFFFF;
            font-family: "Myriadpro-Bold";
            font-size: 1rem;
        }
    }

    .first_step-content {
        padding-top: 7.5rem;

        .content-header {
            .content-header-font {
                text-align: center;
                font-size: 2.25rem;
                line-height: 2.7rem;
                font-family: "MyriadPro-Bold";
            }

        }

        .content-description {
            width: 100%;
            display: flex;
            justify-content: center;

            .content-description-font {
                text-align: center;
                width: 48.3125rem;
                margin-top: 1.875rem;
                margin-bottom: 3.75rem;
                font-family: "Myriadpro";
                font-size: 1.5rem;
                line-height: 1.8rem;
            }

            @media screen and (max-width: 800px) {
                .content-description-font {
                    text-align: none;
                    padding: 0;
                }
            }
        }
    }

    .first_step-footer {
        .btn_custom {
            @include screen_mobile {
                width: 7rem !important;
            }
        }

        .btn_custom_left {
            .btn_custom {
                position: relative;
                margin: 0 1rem;
                border-radius: 1.25rem;
                border: 1px solid #00E2A0 !important;


                .font_btn_custom_left {
                    font-size: 1rem;
                    line-height: 1.888rem;
                    background: linear-gradient(#00E2A0, #00B9B7);
                    font-family: "Myriadpro-Bold";
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
        }

        .btn_custom_right {
            .btn_custom {
                margin: 0 1rem;
                background-image: linear-gradient(to right, #00b9b7, #00e1a0);
                border-radius: 1.25rem;
                color: #FFFFFF;
                font-family: "Myriadpro-Bold";
                font-size: 1rem;
            }

        }
    }
}

@media screen and (max-height: 800px) {

    /*RESET CSS*/
    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        /* font-size: 16px; */
    }

    .first_step-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-size: cover !important;
        background-position: center bottom !important;
    }

    .first_step-container .first_step-content {
        padding-top: 3rem;
    }

    .first_step-container .content-header-completed {
        margin-top: 3rem
    }
    
    .content-header-completed {
        margin-top: 3rem;
    }
}