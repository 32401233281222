@import "/src/_styles/mixin";

.homepage {
  position: relative;
  .slider {
    .slider_bg,
    .slider_bg_mobile {
      width: 100%;
      position: relative;
      height: calc(100vh - 69px);
      min-height: 40rem;
      @include screen_mobile {
        flex-direction: column;
        height: calc(80vh - 70px);
        background-color: #f4fdff;
        min-height: 32rem;
      }

      .slider_1 {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        max-width: 52%;
        height: 100%;
        padding-right: 3%;

        @include screen_mobile {
          align-items: flex-end;
          justify-content: center;
          max-width: 100% !important;
          width: 100%;
          object-fit: contain;
          max-height: 65%;
          padding-right: 0;
          margin: 0 auto;
        }
        .slider_1_img_wrapper {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          min-height: 30rem;
          max-height: calc(45vh - 75px);
          z-index: 5;
          max-width: 84%;
          @include screen_mobile {
            width: 100%;
            max-height: 100% !important;
            min-height: 5rem;
            max-width: 30rem;
            max-width: 98%;
          }
        }
        .slider_1_img {
          object-fit: contain;
          max-height: 100%;
          width: auto;
          z-index: 3;
          height: 100%;
          min-width: 30rem;
          @include screen_mobile {
            width: 90%;
            max-height: 100% !important;
            max-width: 30rem;
            max-height: 100%;
            min-width: 21rem;
          }
        }

        .img_side_container {
          width: 62%;
          bottom: 20%;
          display: flex;
          justify-content: center;
          z-index: 2;
          @include screen_mobile {
            width: 62%;
            bottom: 23%;
            min-width: 17rem;
          }
          .img_side_1 {
            width: 90%;
            position: relative;
            right: -3%;
            z-index: 2;
            max-height: calc(60vh - 75px);

            @include screen_mobile {
              width: 100%;
              min-width: 17rem;
              max-width: 20rem;
              max-height: 100%;
            }
          }
          .img_side_2_container {
            display: flex;
            justify-content: center;
            bottom: 8%;
            width: 90%;
            @include screen_mobile {
              width: 100%;
              // top: 0;
              bottom: 9%;
            }
            .img_side_2 {
              z-index: 1;
              width: 68%;
              position: relative;
              right: -4%;
              max-height: calc(72vh - 75px);

              @include screen_mobile {
                max-width: 60%;
                height: calc(55vh - 75px);
                max-height: calc(43vh - 75px);
                min-height: 11rem;
              }
            }
            .img_side_circle {
              position: absolute;
              right: -2.5%;
              bottom: 5%;
              width: 100%;
              max-width: 30rem;
              max-height: calc(65vh - 75px);

              @include screen_mobile {
                max-height: calc(41vh - 75px);
                min-height: 9.8rem;
              }
            }
          }
        }
      }

      .title_container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 9vh;
        margin-left: 7.5vw;
        @include screen_mobile {
          margin: 0;
          margin-left: 11vw;
          min-height: 35%;
          padding-top: 3.5vh;
        }
        .title_name {
          color: var(--primary-green);
          // font-size: 3.3rem;
          font-size: clamp(2.4rem, 3.3vw, 3.25rem);

          @include screen_mobile {
            font-size: clamp(1.1rem, 5.48vw, 1.5rem);
          }
        }

        .title_detail_2 {
          // font-size: 1.7rem;
          font-size: clamp(1.2rem, 1.63vw, 1.8rem);
          margin-bottom: 2rem;

          @include screen_mobile {
            margin-bottom: 1rem;
            font-size: clamp(0.82rem, 3.6vw, 1.02rem);
          }
        }

        .title_detail_1 {
          color: var(--light-red-color);
          // font-size: 3rem;
          font-size: clamp(2rem, 3.44vw, 3.4rem);

          @include screen_mobile {
            font-size: clamp(1.4rem, 6.74vw, 1.74rem);
          }
        }

        .btn_text {
          font-size: clamp(1.2rem, 2vw, 1.4rem);
          color: var(--white-color);

          @include screen_mobile {
            font-size: clamp(0.73rem, 3vw, 0.86538rem);
          }
        }

        .btn_custom {
          min-width: 15rem;
          @include screen_mobile {
            height: 3rem !important;
            width: 12rem !important;
            max-width: auto;
            min-height: 3rem;
            min-width: 10rem !important;

            @include screen_mobile {
              width: clamp(9.375rem, 35vw, 9.8rem) !important;
              min-width: auto !important;
              height: clamp(2.16rem, 9.5vw, 2.3rem) !important;
              min-height: auto;
            }
          }
        }
      }
    }
  }

  .slider_student {
    // padding-top: 2rem;
    .img_slide_student {
      width: 100%;
    }

    .title_1_student {
      font-size: clamp(2.2rem, 3vw, 2.5rem);
      @include screen_mobile {
        font-size: clamp(0.9375rem, 2.8vw, 1.1rem);
      }
    }

    .title_2_student {
      font-size: clamp(2.8rem, 3vw, 3.125rem);
      @include screen_mobile {
        font-size: clamp(1.125rem, 3vw, 1.3rem);
      }
    }
  }

  .content_main {
    position: relative;
    padding: 4rem 0 4.5rem 0;
    background-color: var(--white-color) !important;
    @include screen_mobile {
      padding-top: 3rem;
    }

    //  Content Student
    .content_student_container {
      padding-bottom: 3rem;
      // List content student

      @include screen_mobile {
        padding-bottom: 3rem;
      }
      .list_content_student {
        width: 50%;
        min-width: 50rem;
        max-width: 60rem;
        z-index: 1;
        @include screen_mobile {
          width: 88%;
          min-width: 88%;
        }

        .img_detail_student_mobile {
          max-width: 27rem;
          min-width: 18rem;
        }
        .item_content_student {
          border: 1px solid var(--border-color);
          border-radius: 1rem;
          padding: 1.2rem 0;
          position: relative;

          @include screen_mobile {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }

          .number_content_student {
            font-size: 4.6875rem;

            @include screen_mobile {
              font-size: 3.125rem;
            }
          }

          .icon_proactive {
            position: absolute;
            top: "20%";
            left: -5.5rem;
            width: 6.5rem;
            height: auto;
            @include screen_mobile {
              transform: scaleX(-1);
              right: -0.5rem;
              left: auto;
              bottom: -25%;
              top: auto;
              width: 5.5rem;
              height: auto;
            }
          }
        }

        .item_content_even {
          width: 100%;
          border-right: none;
          border-top: none;
          .item_detail_even {
            flex-direction: row-reverse;
            opacity: 1;
          }
        }
        .item_content_odd {
          width: 98%;
          left: 2%;
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          @include screen_mobile {
            width: 95%;
            left: 5%;
          }
          .item_detail_odd {
            right: 2%;
            @include screen_mobile {
              right: 5%;
            }
          }
        }
      }

      .list_icon_bg {
        .img_left_top_student {
          left: 0;
          top: 2%;
          z-index: 0;
          img {
            width: 11vw;
            min-width: 8rem;
          }
        }
        .img_left_center {
          left: 0;
          top: 40%;
          z-index: 0;
          img {
            width: 11vw;
            min-width: 8rem;
          }
        }
        .img_right_top {
          right: 0;
          top: 18%;
          z-index: 0;
          img {
            width: 9.5vw;
            min-width: 8rem;
          }
        }
        .img_right_bottom {
          z-index: 0;
          right: 10%;
          bottom: 30%;
          img {
            width: 5.5vw;
            min-width: 4rem;
          }
        }
      }

      .trial_container {
        @include screen_mobile {
          flex-direction: column;
        }
        // Trial Box
        .img_box_trial {
          width: 50%;
          min-width: 20rem;

          @include screen_mobile {
            width: 75%;
            min-width: 15rem;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .content_banner_teacher {
      background-color: var(--bg-light-blue-color) !important;
      padding-bottom: 2rem;

      @include screen_mobile {
        border-bottom-right-radius: 3rem;
      }

      .btn_custom {
        @include screen_mobile {
          background-color: var(--white-color) !important;
        }
      }
    }

    .content_bg_1 {
      width: 100%;
      margin-bottom: 3.5rem;

      @include screen_mobile {
        margin-bottom: 1.5rem;
      }
    }
    .content_title {
      width: 100%;
      .content_title_1 {
        margin-bottom: 2rem;
      }
    }

    .btn_text {
      color: var(--primary-green);
      // font-size: 1.2rem;
    }

    .btn_custom {
      @include screen_mobile {
        height: 3rem !important;
      }
    }

    .content_banner {
      margin: 3rem 0 1rem 0;
      @include screen_mobile {
        margin-top: 0rem;
      }
      .img_banner {
        margin: 0.3rem 0 2.3rem 0;
        max-width: 85%;

        @include screen_mobile {
          max-width: 100%;
          margin-top: 0rem;
        }
      }
      .list_detail_banner {
        margin: 1.8rem 0 1rem 0;
      }

      .list_detail_parent {
        margin-right: 5%;
      }

      .icon_left_container {
        position: absolute;
        position: absolute;
        left: 0;
        .icon_text_left {
          width: 15vw;
          min-width: 8rem;
        }
      }

      .icon_center_container {
        position: absolute;
        left: 52%;
        top: 2px;

        @include screen_mobile {
          left: 65%;
          top: 1rem;
        }
        .icon_bonus_center {
          width: 7vw;
          min-width: 6.5rem;

          @include screen_mobile {
            width: 13vw;
            min-width: 4.5rem;
          }
        }
      }

      .icon_right_container {
        position: absolute;
        right: 0;
        .icon_text_right {
          width: 13vw;
          min-width: 8rem;
        }
      }

      .icon_left_teacher_container {
        position: absolute;
        left: 0;
        bottom: 0;
        img {
          width: 10vw;
          min-width: 9.5rem;
        }
      }
      .icon_dots_container {
        position: absolute;
        left: 0;
        top: 8rem;
        img {
          width: 2vw;
          min-width: 1.5rem;
        }
      }
      .icon_right_teacher_container {
        position: absolute;
        right: 0;
        bottom: 8rem;
        img {
          width: 10vw;
          min-width: 9.5rem;
        }
      }
      .icon_top_teacher_container {
        position: absolute;
        top: -7rem;
        left: 5%;
        img {
          width: 4.5vw;
          min-width: 4rem;
        }
      }
      .icon_half_teacher_container {
        position: absolute;
        top: -10rem;
        right: 0;
        img {
          width: 5vw;
          min-width: 4.5rem;
        }
      }
    }
  }
}
