@import "/src/_styles/mixin";

$red: #e22028;
$border-color: #4a4848;

.input_date_base_container {
  position: relative;
  .error_input {
    span {
      color: $red;
      font-size: 18px;
    }
  }
  .error_text {
    margin-bottom: 9px;
  }
  .error_text_absolute {
    position: absolute;
    bottom: calc(100% + 2px);
  }
  .input_date_base {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0px 22px;
    border: 1px solid $border-color;
    border-radius: 10px;
    margin-bottom: 22px;
    .icon_label {
      width: 31px;
      margin-right: 26px;
      flex: none;
      @include screen_mobile {
        margin-right: 1rem !important;
      }
    }
    .react-datepicker-wrapper {
      height: 100%;
      .react-datepicker__input-container {
        height: 100%;
      }
    }
    input {
      height: 100%;
      border: none;
      font-size: 18px;
      line-height: 21px;
      background-color: transparent;
      width: 100%;
      font-family: "Myriadpro-Regular";
      &:focus {
        outline: none;
      }
      @include screen_mobile {
        font-size: 0.9rem !important;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: "Myriadpro-Regular";
        color: #8e9298;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: "Myriadpro-Regular";
        color: #8e9298;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        font-family: "Myriadpro-Regular";
        color: #8e9298;
      }
    }
    select {
      font-family: "Myriadpro-Regular";
      color: #8e9298;
    }
  }
}
