.register_step {
    .title_register {
        margin-bottom: 18px;
    }
    .step_register_name {
        font-size: 24px;
        line-height: 28px;
    }
    .login_text {
        p,
        a {
            font-size: 18px;
        }
    }
}

.register_create_account {
    .step_register_name {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 36px;
    }
}
