@import "/src/_styles/mixin";

.deleteAccountPage {
  .content {
    align-items: center;
    background-color: #eefdf9;
    padding: 3rem 4.5%;

    @include screen_mobile {
      padding: 2.5rem 0 1.5rem;
    }

    .delete_accound_title {
      font-size: clamp(1.5rem, 3vw, 2rem);

      @include screen_mobile {
        font-size: clamp(1.35rem, 6vw, 1.5rem);
      }
    }

    .content_steps {
      padding: 2rem 0 1rem;
      align-items: flex-start;

      @include screen_mobile {
        padding: 1rem 0 0.5rem;
        align-items: center;
      }

      .delete_step_item {
        width: 42%;
        max-width: 42%;
        // margin: 0 1.5%;
        padding: 1.5rem 0;
        min-height: 25rem;
        // border: 1px solid var(--border-color);
        // border-radius: 0.5rem;
        // background-color: var(--white-color);

        @include screen_mobile {
          width: 92%;
          max-width: 92%;
          padding: 1.15rem 0;
        }

        .delete_step_item_title {
          padding: 1.3rem 0rem 1.7rem;
          font-size: clamp(1.4rem, 4vw, 1.5rem);

          @include screen_mobile {
            padding-top: 0;
            font-size: clamp(1.125em, 3vw, 1.25rem);
          }

          span {
            font-size: clamp(1.4rem, 4vw, 1.5rem);

            @include screen_mobile {
              font-size: clamp(1.125em, 3vw, 1.25rem);
            }
          }
        }

        .img_step_delete {
          //   padding: 1rem;
          width: 58%;
          min-width: 15rem;

          @include screen_mobile {
            width: 75%;
            min-width: 5rem;
          }
          img {
            border: 1px solid var(--border-color);
          }
        }

        .delete_step_detail {
          //   width: 62%;
          font-size: clamp(1rem, 1.8vw, 1.125rem);
          padding: 1.7rem 0;

          @include screen_mobile {
            width: 90%;
            font-size: clamp(0.9rem, 1.8vw, 1rem);
            padding: 1.3rem 0;
          }
        }
      }

      .icon_fast_arrow_horizontal {
        margin-top: 25%;
        img {
          width: clamp(4rem, 6.8vw, 6rem);
        }
      }

      .icon_fast_arrow_3 {
        img {
          transform: scaleX(-1);
        }
      }
      .icon_fast_arrow_vertical {
        padding-top: 1.7rem;

        @include screen_mobile {
          padding-top: 1rem;
        }
        img {
          width: clamp(4rem, 6.8vw, 6rem);
          transform: rotate(90deg);

          @include screen_mobile {
            width: 3rem;
          }
        }
      }
    }

    .content_step_after {
      flex-direction: row-reverse;
      align-items: flex-start;
      @include screen_mobile {
        align-items: center;
        flex-direction: column;
      }
    }
  }
}
