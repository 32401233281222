/* Curriculum */
#sunE_map_curriculum {
  transition: transform 0.3s ease;
}

.sunE_map_curriculum {
  min-height: 66vh !important;
  .footer_curriculum {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.8rem 0 1rem;
    //   bottom: 0;
    .btn-primary {
      border: none;
      padding: 0px 2rem;
      margin: 0px 1rem;
      border-radius: 1.5rem;
      color: #fff;
      // margin-left: 6px;
      height: 39px;
      font-family: "Myriadpro-SemiBold";
      font-size: 18px;
      margin-top: 3px;
      background-image: linear-gradient(to right, #00b9b7, #00e1a0);
      display: flex;
      align-items: center;

      &:disabled {
        background: #70707070;
      }
    }
  }
}

// Home Page Study Guide Student
.ico_zoom_out {
  bottom: 0.7rem;
  right: 0.4rem;
  width: 2.2rem;
  z-index: 2;
}
